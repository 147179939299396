import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Button, Container, Spinner } from "reactstrap";
import styled from "styled-components";

const PageContainer = styled(Container)`
  height: 100vh;
  width: 100vw;
`;

const Auth0Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  });
  if (isAuthenticated) {
    return (
      <PageContainer className="d-flex align-items-center justify-content-center">
        <Button>Login</Button>
      </PageContainer>
    );
  }
  return (
    <PageContainer className="d-flex align-items-center justify-content-center">
      <h1>Loading... </h1>
      <Spinner type="grow" color="danger" />
    </PageContainer>
  );
};

export default Auth0Login;
