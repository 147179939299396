import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

// const initialState = {};

const enhancers = [];
const middleware = [thunk];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = () => {
  const store = createStore(rootReducer, composedEnhancers);
  return { store };
};

export default configureStore;
