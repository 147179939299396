export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_VOLUNTEERS = "FETCH_VOLUNTEERS";
export const FETCH_COUNSELLORS = "FETCH_COUNSELLORS";
export const FETCH_SESSIONS = "FETCH_SESSIONS";
export const FETCH_UNSCHEDULED_SESSIONS = "FETCH_UNSCHEDULED_SESSIONS";
export const FETCH_PAST_SESSIONS = "FETCH_PAST_SESSIONS";
export const FETCH_TOPICS = "FETCH_TOPICS";
export const FETCH_LISTENING_EAR = "FETCH_LISTENING_EAR";
export const FETCH_UNSCHEDULED_LISTENING_EAR =
  "FETCH_UNSCHEDULED_LISTENING_EAR";
export const FETCH_PAST_LISTENING_EAR = "FETCH_PAST_LISTENING_EAR";
export const FETCH_TOPIC_CATEGORIES = "FETCH_TOPIC_CATEGORIES";
export const FETCH_INTAKE_QUESTIONNAIRE = "FETCH_INTAKE_QUESTIONNAIRE";
