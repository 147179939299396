import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import { fetchTopicCategories } from "../../redux/actions/topicCategory";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Api from "../../utils/api";
import { NotificationManager } from "react-notifications";
import { fetchTopics } from "../../redux/actions/topics";

const Button = styled.button`
  height: 44px;
  background-color: #e236f1;
  border: 0;
  color: #ffffff;
  border-radius: 40px;
`;

function CreateTopic({ modal, toggle }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const submitData = async (data) => {
    setLoading(true);
    await Api()
      .addTopic(data)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            reset();
            dispatch(fetchTopics());
            NotificationManager.success(res.data.message, "Success");
            toggle();
          } else {
            NotificationManager.error(res.data.message, "An error occurred");
          }
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const onSubmit = (data) => {
    const body = new FormData();
    for (const property in data) {
      //   console.log(property, data[property]);
      body.append(`${property}`, data[property]);
    }
    submitData(body);
  };

  const dispatch = useDispatch();
  const topicCategories = useSelector(
    (state) => state.topicCategories.topicCategories
  );

  useEffect(() => {
    dispatch(fetchTopicCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal isOpen={modal} toggle={toggle} unmountOnClose>
      <ModalBody>
        <h4 className="text-center mb-4 mt-3">Create Topic</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Title</Label>
            <Input
              invalid={errors.title}
              placeholder="Enter title"
              {...register("title", { required: true, maxLength: 60 })}
            />
            <FormFeedback>Title must be 60 charaters or less</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              invalid={errors.description}
              placeholder="Enter description"
              type="textarea"
              {...register("description", { required: true, minLength: 10 })}
            />
            <FormFeedback>
              description must be 10 charaters or more
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Category</Label>
            <Input
              invalid={errors.category}
              type="select"
              {...register("category", { required: true })}
            >
              <option value="none" disabled>
                choose category
              </option>
              {topicCategories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Input>
            <FormFeedback>
              Select the category that best fits this topic
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Add Topic Image</Label>
            <Input
              name="picture"
              type="file"
              {...register("picture", { required: false })}
              onChange={(e) => setValue("image", e.target.files[0])}
            />
          </FormGroup>
          <div className="d-flex mt-5">
            <Button className="w-100" disabled={loading}>
              {!loading ? "Submit" : <Spinner />}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default CreateTopic;
