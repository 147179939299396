import { combineReducers } from "redux";
import dashboard from "./dashboard";
import users from "./users";
import volunteers from "./volunteers";
import counsellors from "./counsellors";
import sessions from "./sessions";
import listeningEar from "./listeningEar";
import topics from "./topics";
import topicCategories from "./topicCategory";
import intakeForms from "./intakeForms";

const rootReducer = combineReducers({
  dashboard,
  users,
  counsellors,
  volunteers,
  sessions,
  listeningEar,
  topics,
  topicCategories,
  intakeForms,
});

export default rootReducer;
