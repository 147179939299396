import React, { useEffect, useState } from "react";
import DataTable, {
  ActionButtonContainer,
  OutlinedButton,
  StyledToggleDropDown,
  TableContainer,
} from "../components/DataTable";
import FilterBanner from "../components/FilterBanner";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import UserDropDown from "../components/UserDropDown";
import { Container, UncontrolledButtonDropdown } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ProfileModal from "../components/modals/ProfileModal";
import { fetchCounsellors } from "../redux/actions/counsellors";

function CounsellorList() {
  const [activeProfile, setActiveProfile] = useState("");
  const [showProfileModal, setProfileModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const counsellors = useSelector((state) => state.counsellors.counsellors);
  const [searchText, setSearchText] = useState("");

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  const toggleProfileModal = () => setProfileModalVisibility(!showProfileModal);

  useEffect(() => {
    dispatch(fetchCounsellors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: "User ID",
      accessor: "_id",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: " ",
      Cell: ({ row }) => {
        const { original } = row;
        const { _id } = original;
        return (
          <div className="d-flex justify-content-end">
            <OutlinedButton
              className="px-4"
              onClick={() => {
                setActiveProfile(_id);
                toggleProfileModal();
              }}
            >
              View Profile
            </OutlinedButton>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { name, _id, dateCreated = Date.now() } = original;
        // console.log(original);
        return (
          <div className="d-flex justify-content-start">
            <UncontrolledButtonDropdown direction="up">
              <StyledToggleDropDown caret={false} className="p-0">
                <ActionButtonContainer>
                  <EllipsisH />
                </ActionButtonContainer>
              </StyledToggleDropDown>
              <UserDropDown
                name={name}
                date={dateCreated}
                options={[
                  {
                    name: 'View User Profile',
                    onClick: () => {
                      setActiveProfile(_id);
                      toggleProfileModal();
                    },
                  },
                  // { name: "View Upcoming Sessions", onClick: () => {} },
                  // { name: "View Session History", onClick: () => {} },
                  // { name: "Assign Badge", onClick: () => {} },
                  { name: 'Disable Account', onClick: () => {} },
                  { name: 'Delete User', onClick: () => {} },
                ]}
              />
            </UncontrolledButtonDropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Container fluid className="px-0">
      <ProfileModal
        modal={showProfileModal}
        toggle={toggleProfileModal}
        activeProfile={activeProfile}
      />
      <FilterBanner value={searchText} onInputChange={onInputChange} />
      <TableContainer className="mt-3 mb-5 py-3">
        <DataTable
          DATACOLUMNS={columns}
          TABLEDATA={counsellors}
          searchtext={searchText}
        />
      </TableContainer>
    </Container>
  );
}

export default CounsellorList;
