import React, { useEffect, useState } from "react";
import { Container, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Api from "../../utils/api";

function ViewSessionNotes({ modal, toggle, activeSession }) {
  const [isLoading, setLoading] = useState(false);
  const [note, setNote] = useState({});

  const loadData = async () => {
    setLoading(true);
    await Api()
      .getSessionNote(activeSession)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            setNote(res.data.note);
          }
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };
  useEffect(() => {
    if (activeSession) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession]);
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ borderBottom: 0 }}>
        {note?.title ?? "Session Notes"}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          note?.content ?? "No Note Entered for this session"
        ) : (
          <Container className="d-flex justify-content-center">
            <Spinner />
          </Container>
        )}
      </ModalBody>
    </Modal>
  );
}

export default ViewSessionNotes;
