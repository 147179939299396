import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { fetchVolunteers } from "../../redux/actions/volunteers";
import Api from "../../utils/api";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SelectButton = styled.button`
  width: 100%;
  height: 53px;
  background: ${({ active }) => (active ? "#E236F1" : "#ffffff")};
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 200px;
`;

const TextArea = styled(Input)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 16px;
  height: 108px;
`;

const SubmitBtn = styled.button`
  width: 100%;
  height: 56px;
  background: #e236f1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(176, 176, 176, 0.25);
  border-radius: 200px;
  font-size: 20px;
  color: #ffffff;
`;

function RejectVolunteerModal({ modal, toggle, _id, name }) {
  const dispatch = useDispatch();
  const [volunteerRejectionReason, setVolunteerRejectionReason] = useState("");

  const handleRejection = () => {
    return Api()
      .rejectVolunteer(_id, { volunteerRejectionReason })
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchVolunteers("unapproved"));
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };
  return (
    <Modal isOpen={modal} toggle={toggle} size="sm">
      <ModalHeader>Reject {name}</ModalHeader>
      <ModalBody>
        <div>
          <SelectButton
            active={volunteerRejectionReason === "Incomplete Information"}
            onClick={() =>
              setVolunteerRejectionReason("Incomplete Information")
            }
            className="mb-3"
          >
            Incomplete Information
          </SelectButton>
          <SelectButton
            active={volunteerRejectionReason === "Candidate not suited"}
            onClick={() => setVolunteerRejectionReason("Candidate not suited")}
          >
            Candidate not suited
          </SelectButton>
          <h5 className="text-center my-4">OR</h5>
          <TextArea
            type="textarea"
            value={
              volunteerRejectionReason === "Incomplete Information" ||
              volunteerRejectionReason === "Candidate not suited"
                ? ""
                : null
            }
            onChange={(e) => setVolunteerRejectionReason(e.target.value)}
          />
          <SubmitBtn
            className="mt-4"
            onClick={() =>
              Swal.fire({
                icon: "warning",
                title: "Are you sure you want to reject this volunteer?",
                confirmButtonText: "Proceed",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                preConfirm: (login) => {
                  return handleRejection(_id);
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((res) => {
                if (res.isDismissed) {
                  toggle();
                }
              })
            }
          >
            submit
          </SubmitBtn>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default RejectVolunteerModal;
