import { FETCH_COUNSELLORS } from "../actions";

const initialState = {
  counsellors: [],
  isLoading: false,
};

export default function counsellors(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNSELLORS:
      return {
        ...state,
        counsellors: action.payload,
      };
    default:
      return state;
  }
}
