import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

const Line = styled.div`
  height: 0.3px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`;

const Indicator = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background-color: ${({ color }) => color};
`;

function ViewTopic({ modal, toggle, title, description, image, category }) {
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalBody className="p-0">
        {image?.url && (
          <div
            style={{
              height: "170px",
              width: "100%",
              borderTopLeftRadius: "18px",
              borderTopRightRadius: "18px",
              backgroundImage: `url(${image.url})`,
            }}
          ></div>
        )}
        <div className="px-5">
          <h3 className="mt-4" style={{ fontWeight: "700" }}>
            {title}
          </h3>
          <div className="d-flex align-items-center">
            <Indicator className="mr-1" color={category.color_code} />
            <p className="p-0 m-0">{category.name}</p>
          </div>
          <Line className="my-4" />
          <p>{description}</p>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ViewTopic;
