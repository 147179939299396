import React from "react";
import Avatar from "react-avatar";
import { Container, Modal, ModalBody, Row } from "reactstrap";
import styled from "styled-components";
import dayjs from "dayjs";
import { ReactComponent as Calendar } from "../../assets/images/calendar.svg";
import { ReactComponent as Clock } from "../../assets/images/clock.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Api from "../../utils/api";
import { useDispatch } from "react-redux";
import { fetchSessions } from "../../redux/actions/sessions";

const MySwal = withReactContent(Swal);

const Header = styled(Container)`
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.2);
`;

const AvatarLabel = styled.p`
  font-size: 18px;
  color: #000000;
  font-family: SFProDisplay-Bold;
`;

const Title = styled.h6`
  font-size: 1rem;
  color: #000000;
  font-family: SFProDisplay-Bold;
`;

const Chip = styled.div`
  height: 34px;
  background-color: ${({ color }) => color};
  font-size: 0.7rem;
  color: #000000;
  font-family: SFProDisplay-Regular;
  border-radius: 30px;
`;

const DateLabel = styled.p`
  font-family: SFProDisplay-Regular;
  font-size: 0.8rem;
  color: #000000;
`;

const OutlinedButton = styled.button`
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(176, 176, 176, 0.25);
  border-radius: 200px;
  background: #ffffff;
  &:hover {
    background: #e236f1;
    color: #ffffff;
  }
`;

function ViewSessionDetails({ modal, toggle, onEdit, original }) {
  const { user, counsellor, _id } = original;
  const dispatch = useDispatch();

  const handleSessionDelete = (_id) => {
    return Api()
      .deleteSession(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchSessions());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalBody className="px-0 pt-5">
        <>
          <Header className="pb-5 mb-2" fluid>
            <Row className="justify-content-center">
              <Avatar name={user?.name ?? ""} size="27" round />
              <AvatarLabel className="ml-4 m-0">{user?.name ?? ""}</AvatarLabel>
            </Row>
            {counsellor ? (
              <Row className="justify-content-center mt-3">
                <Avatar name={counsellor?.name ?? ""} size="27" round />
                <AvatarLabel className="ml-4 m-0">
                  {counsellor?.name ?? ""}
                </AvatarLabel>
              </Row>
            ) : (
              <h6 className="mt-3 text-center">
                No counsellor has been assigned to this session
              </h6>
            )}
          </Header>
          <Container className="px-4">
            <Title className="mb-4">{original.name}</Title>
            {original.sessionCategories && (
              <Row className="px-3">
                {original.sessionCategories.map((el) => (
                  <Chip
                    key={el._id}
                    color={el.color_code}
                    className="mr-2 px-2 d-flex align-items-center"
                  >
                    {el.name}
                  </Chip>
                ))}
              </Row>
            )}
            <Row className="px-3 mt-3 mb-1">
              <Calendar />
              <DateLabel>
                &nbsp;&nbsp;{dayjs(original.startTime).format("Do MMMM YYYY")}
              </DateLabel>
            </Row>
            <Row className="px-3">
              <Clock />
              <DateLabel>
                &nbsp;&nbsp;{dayjs(original.startTime).format("hh:mm A")} -{" "}
                {dayjs(original.endTime).format("hh:mm A")}
              </DateLabel>
            </Row>
            <div className="d-flex flex-column mt-5">
              <OutlinedButton
                className="mb-3"
                onClick={() => {
                  onEdit();
                  toggle();
                }}
              >
                {original?.counsellor?._id
                  ? "reschedule session"
                  : "schedule session"}
              </OutlinedButton>
              <OutlinedButton
                onClick={() => {
                  toggle();
                  return MySwal.fire({
                    icon: "question",
                    title: "Are you sure you want to delete this session?",
                    confirmButtonText: "Proceed",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    preConfirm: (login) => {
                      return handleSessionDelete(_id);
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                  });
                }}
              >
                Delete Session
              </OutlinedButton>
            </div>
          </Container>
        </>
      </ModalBody>
    </Modal>
  );
}

export default ViewSessionDetails;
