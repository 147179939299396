import {
  FETCH_PAST_SESSIONS,
  FETCH_SESSIONS,
  FETCH_UNSCHEDULED_SESSIONS,
} from "../actions";

const initialState = {
  sessions: [],
  unscheduledSessions: [],
  pastSessions: [],
  isLoading: false,
};

export default function sessions(state = initialState, action) {
  switch (action.type) {
    case FETCH_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      };
    case FETCH_UNSCHEDULED_SESSIONS:
      return {
        ...state,
        unscheduledSessions: action.payload,
      };
    case FETCH_PAST_SESSIONS:
      return {
        ...state,
        pastSessions: action.payload,
      };
    default:
      return state;
  }
}
