import { FETCH_INTAKE_QUESTIONNAIRE } from "../actions";

const initialState = {
  questionnaire: [],
};

export default function intakeForms(state = initialState, action) {
  switch (action.type) {
    case FETCH_INTAKE_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: action.payload,
      };
    default:
      return state;
  }
}
