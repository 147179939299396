import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import styled from "styled-components";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";
import DashboardLayout from "../layouts/DashboardLayout";
import newUsers from "../assets/images/newUsers.png";
import totalSessions from "../assets/images/totalSessions.png";
import activeCounsellors from "../assets/images/activeCounsellors.png";
import { ReactComponent as CaretDown } from "../assets/images/caret-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../redux/actions/dashboard";

const PageContainer = styled(Container)`
  background: #f4f4f4;
  border-radius: 4px;
`;

const Card = styled(Row)`
  background-color: #ffffff;
  height: 30%;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 768px) {
    height: 164px;
    margin-bottom: 10px;
  }
`;

const GraphTop = styled(Row)`
  height: 20%;
  align-items: center;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const GraphContainer = styled(Row)`
  height: 80%;
  // background-color: pink;
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Image = styled.img`
  height: 2rem;
  width: 2rem;
  object-fit: contain;
`;

const CardCount = styled.h5`
  color: #e236f1;
  font-family: SFProDisplay-Bold;
  font-size: 56px;
`;

const ImageContainer = styled.div`
  background-color: #fefcf4;
  border-radius: 100px;
  padding: 15px;
`;

const DropDownButton = styled(DropdownToggle)`
  height: 50px;
  width: 178px;
  border: 0;
  background-color: #ffffff;
  border-radius: 200px;
  color: #000000;
  font-family: SFProDisplay-Regular;
  font-size: 0.9rem;
  &.dropdown-toggle::after {
    display: none;
  }
  &:hover {
    border: 0 !important;
    outline: 0 !important;
    color: #000000 !important;
    background-color: #ffffff !important;
  }
`;

function Dashboard() {
  const [period, setPeriod] = useState("month");
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboard(period));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const handlePeriodSelect = (e) => {
    setPeriod(e.target.value);
  };
  return (
    <DashboardLayout>
      <PageContainer className="my-5 h-100 wide-container">
        <Row className="h-100 mx-3 py-3">
          <Col
            xs="12"
            sm="12"
            md="4"
            lg="4"
            className="h-100 d-flex flex-column justify-content-between"
          >
            <Card className="p-1">
              <ImageContainer>
                <Image src={totalSessions} />
              </ImageContainer>
              <CardCount>{dashboard?.totalSessions}</CardCount>
              <p>
                Total
                <br />
                Sessions
              </p>
            </Card>
            <Card className="p-1">
              <ImageContainer>
                <Image src={newUsers} />
              </ImageContainer>
              <CardCount>{dashboard?.newUsers}</CardCount>
              <p>
                All
                <br />
                Users
              </p>
            </Card>
            <Card className="p-1">
              <ImageContainer>
                <Image src={activeCounsellors} />
              </ImageContainer>
              <CardCount>{dashboard?.activeCounsellors}</CardCount>
              <p>
                Active
                <br />
                Counsellors
              </p>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="7" lg="7">
            <Container className="h-100 offset-0 offset-md-1">
              <GraphTop className="justify-content-between">
                <p>Sessions per day</p>
                <UncontrolledButtonDropdown>
                  <DropDownButton
                    caret
                    className="d-flex justify-content-between align-items-center"
                  >
                    {period}
                    <CaretDown />
                  </DropDownButton>
                  <DropdownMenu>
                    <DropdownItem value="week" onClick={handlePeriodSelect}>
                      This Week
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem value="month" onClick={handlePeriodSelect}>
                      This Month
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </GraphTop>
              <GraphContainer>
                <VictoryChart
                  //   theme={VictoryTheme.material}
                  height={100}
                  padding={{ bottom: -20, top: -50, left: 20, right: 20 }}
                  animate={{ duration: 500 }}
                  // domainPadding={10}
                >
                  <VictoryAxis
                    crossAxis
                    style={{
                      axis: { stroke: 0 },
                      tickLabels: {
                        fontSize: 10,
                        padding: 20,
                        angle: period === "month" ? 270 : 0,
                      },
                    }}
                    tickFormat={(tick) =>
                      tick.length > 5
                        ? `${tick.substr(0, 4)} ${tick.substr(tick.length - 2)}`
                        : tick
                    }
                  />
                  <VictoryAxis
                    tickFormat={() => ""}
                    dependentAxis
                    style={{ axis: { stroke: 0 } }}
                  />
                  <VictoryBar
                    style={{ data: { fill: "#E236F1" } }}
                    alignment={period === "month" ? "start" : "middle"}
                    // animate={{
                    //   onExit: {
                    //     duration: 500,
                    //     before: () => ({
                    //       _y: 0,
                    //       fill: "rgba(226, 54, 241, 0.26)",
                    //       label: "0",
                    //     }),
                    //   },
                    // }}
                    data={dashboard?.dashboard ?? []}
                  />
                </VictoryChart>
              </GraphContainer>
            </Container>
          </Col>
        </Row>
      </PageContainer>
    </DashboardLayout>
  );
}

export default Dashboard;
