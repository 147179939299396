import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Modal, ModalBody } from "reactstrap";
import DataTable, {
  OutlinedButton,
  TableContainer,
} from "../components/DataTable";
import FilterBanner from "../components/FilterBanner";
import DashboardLayout from "../layouts/DashboardLayout";
import { fetchIntakeQuestionnaire } from "../redux/actions/intakeForms";

dayjs.extend(advancedFormat);

function IntakeList() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const questionnaire = useSelector((state) => state.intakeForms.questionnaire);

  useEffect(() => {
    dispatch(fetchIntakeQuestionnaire());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  const columns = [
    {
      Header: "User's Name",
      accessor: 'user.name',
    },
    {
      Header: "User's Email",
      accessor: 'user.email',
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => {
        const [modal, setModal] = useState(false);

        const toggle = () => {
          setModal(!modal);
        };

        const content = row.original;

        console.log(content);
        return (
          <>
            <OutlinedButton className="px-4" onClick={toggle}>
              View User Response
            </OutlinedButton>
            <Modal
              isOpen={modal}
              toggle={toggle}
              size="lg"
              centered
              scrollable="false"
              contentClassName="intake-modal"
            >
              {/* <ModalHeader toggle={toggle}>
                <h2>User Intake Response</h2>
              </ModalHeader> */}
              <ModalBody>
                {content.response.map((el) => (
                  <div>
                    <h3>{el.question?.title}</h3>
                    <p>{el.selectedOption?.join(', ')}</p>
                    <p>{el.textFieldResponse}</p>
                  </div>
                ))}
              </ModalBody>
            </Modal>
          </>
        );
      },
    },
  ];
  return (
    <DashboardLayout autoheight>
      <FilterBanner value={searchText} onInputChange={onInputChange} />
      <TableContainer className="mt-3 mb-5 py-3">
        <DataTable
          DATACOLUMNS={columns}
          TABLEDATA={questionnaire}
          searchtext={searchText}
        />
      </TableContainer>
    </DashboardLayout>
  );
}

export default IntakeList;
