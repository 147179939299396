import {
  FETCH_LISTENING_EAR,
  FETCH_PAST_LISTENING_EAR,
  FETCH_UNSCHEDULED_LISTENING_EAR,
} from "../actions";

const initialState = {
  listeningEar: [],
  unscheduledListeningEar: [],
  pastListeningEar: [],
  isLoading: false,
};

export default function listeningEar(state = initialState, action) {
  switch (action.type) {
    case FETCH_LISTENING_EAR:
      return {
        ...state,
        listeningEar: action.payload,
      };
    case FETCH_UNSCHEDULED_LISTENING_EAR:
      return {
        ...state,
        unscheduledListeningEar: action.payload,
      };
    case FETCH_PAST_LISTENING_EAR:
      return {
        ...state,
        pastListeningEar: action.payload,
      };
    default:
      return state;
  }
}
