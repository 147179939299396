import Counsellors from "../../pages/Counsellors";
import Dashboard from "../../pages/Dashboard";
import IntakeList from "../../pages/IntakeList";
import ListeningEar from "../../pages/ListeningEar";
import Sessions from "../../pages/Sessions";
import TopicsList from "../../pages/TopicsList";
import Users from "../../pages/Users";

const dashboardRoute = [
  {
    name: "Dashboard",
    path: "/dashboard",
    exact: true,
    children: <Dashboard />,
  },
  {
    name: "Users",
    exact: false,
    path: "/dashboard/users",
    children: <Users />,
  },
  {
    name: "Counsellors",
    path: "/dashboard/counsellors",
    children: <Counsellors />,
  },
  {
    name: "Sessions",
    path: "/dashboard/sessions",
    children: <Sessions />,
  },
  {
    name: "Listening Ear",
    path: "/dashboard/listening-ear",
    children: <ListeningEar />,
  },
  {
    name: "Forums",
    path: "/dashboard/forums",
    children: <TopicsList />,
  },
  {
    name: "Intake Forms",
    path: "/dashboard/intake-forms",
    children: <IntakeList />,
  },
  // {
  //   name: "Tools",
  //   path: "/dashboard/tools",
  //   children: <h1>Tools</h1>,
  // },
];

export default dashboardRoute;
