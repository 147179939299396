import React, { useState } from "react";
import Avatar from "react-avatar";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import styled from "styled-components";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import EditTopic from "./modals/EditTopic";
import ViewTopic from "./modals/ViewTopic";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Api from "../utils/api";
import { fetchTopics } from "../redux/actions/topics";
import { useDispatch } from "react-redux";
import {
  ActionButtonContainer,
  StyledToggleDropDown,
} from "../components/DataTable";

const MySwal = withReactContent(Swal);

const Card = styled.div`
  height: 257px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
`;

const Title = styled.h6`
  font-family: SFProDisplay-Regular;
  color: #610069;
  font-size: 15px;
`;

const Description = styled.p`
  font-family: SFProDisplay-Regular;
  font-size: 10px;
`;

const CommentInfo = styled.p`
  font-family: SFProDisplay-Regular;
  font-size: 10px;
`;

function TopicCard({ row }) {
  const [showTopicDetails, setTopicDetailsVisibility] = useState(false);
  const [showEditModal, setEditModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const { original } = row;
  const {
    title,
    description,
    author = { name: "" },
    commentCount = 0,
    _id,
  } = original;

  const toggleEditModal = () => {
    setEditModalVisibility(!showEditModal);
  };

  const toggleDetailsVisibility = () =>
    setTopicDetailsVisibility(!showTopicDetails);

  const handleTopicDelete = (_id) => {
    return Api()
      .deleteTopic(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchTopics());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  return (
    <>
      <ViewTopic
        modal={showTopicDetails}
        toggle={toggleDetailsVisibility}
        {...original}
      />
      <EditTopic
        modal={showEditModal}
        toggle={toggleEditModal}
        original={original}
      />
      <Col xs="12" sm="12" md="6" lg="4" className="mb-3">
        <Card className="p-5 w-100 d-flex flex-column justify-content-between">
          <Row>
            <Col xs="9" sm="9" md="9" lg="9" className="px-0">
              <Title>{title}</Title>
            </Col>
            <Col
              xs="3"
              sm="3"
              md="3"
              lg="3"
              className="d-flex justify-content-end px-0"
            >
              <UncontrolledButtonDropdown direction="up">
                <StyledToggleDropDown
                  caret={false}
                  className="p-0"
                  style={{ backgroundColor: "white", height: "25px" }}
                >
                  <ActionButtonContainer style={{ backgroundColor: "white" }}>
                    <EllipsisH fill="#000000" />
                  </ActionButtonContainer>
                </StyledToggleDropDown>
                <DropdownMenu>
                  <DropdownItem onClick={toggleDetailsVisibility}>
                    View
                  </DropdownItem>
                  <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      Swal.fire({
                        icon: "warning",
                        title: "Are you sure you want to delete this topic?",
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        preConfirm: (login) => {
                          return handleTopicDelete(_id);
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
          </Row>
          <Row>
            <Description>{description.substr(0, 150)}</Description>
          </Row>
          <Row className="align-items-center">
            <Avatar name={author?.name ?? "user"} size="20" round />
            {author?.name && (
              <CommentInfo className="mb-0">
                {" "}
                &nbsp;{author?.name ?? "user"}
              </CommentInfo>
            )}
            {commentCount > 0 && (
              <CommentInfo className="mb-0">
                &nbsp;and {commentCount} others
              </CommentInfo>
            )}
          </Row>
        </Card>
      </Col>
    </>
  );
}

export default TopicCard;
