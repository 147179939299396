import React, { useEffect, useState } from "react";
import DataTable, {
  ActionButtonContainer,
  StyledToggleDropDown,
  TableContainer,
} from "../components/DataTable";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import styled from "styled-components";
import FilterBanner from "../components/FilterBanner";
import { ReactComponent as GridIcon } from "../assets/images/grid-icon.svg";
import { ReactComponent as ListIcon } from "../assets/images/list-icon.svg";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../layouts/DashboardLayout";
import { fetchTopics } from "../redux/actions/topics";
import TopicCard from "../components/TopicCard";
import CreateTopic from "../components/modals/CreateTopic";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import Swal from "sweetalert2";
import Api from "../utils/api";
import withReactContent from "sweetalert2-react-content";
import ViewTopic from "../components/modals/ViewTopic";
import EditTopic from "../components/modals/EditTopic";

const MySwal = withReactContent(Swal);

dayjs.extend(advancedFormat);

const AddButton = styled.button`
  width: 187px;
  height: 52px;
  background: #e236f1;
  border: 0px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 0px 9px 2px rgba(176, 176, 176, 0.25);
  border-radius: 10px;
  color: #ffffff;
  font-family: SFProDisplay-Bold;
`;

const IconButton = styled.button`
  height: 45px;
  width: 45px;
  border-radius: 5px;
  border: 0;
  background-color: ${({ active }) =>
    active ? "rgba(233, 87, 245, 0.51)" : "transparent"};
`;

function TopicsList() {
  const [tableLayout, setTableLayout] = useState("list");
  const [showCreateModal, setCreateModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.topics);
  const [searchText, setSearchText] = useState("");

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  const switchTableLayout = (type = "list") => {
    setTableLayout(type);
  };

  const toggleCreateModal = () => {
    setCreateModalVisibility(!showCreateModal);
  };

  const handleTopicDelete = (_id) => {
    return Api()
      .deleteTopic(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchTopics());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  useEffect(() => {
    dispatch(fetchTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: "Topic",
      accessor: "title",
    },
    {
      Header: "Author",
      accessor: "author.name",
    },
    {
      Header: "Date Added",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return dayjs(value).format("Do MMM YYYY");
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { _id } = original;
        const [showTopicDetails, setTopicDetailsVisibility] = useState(false);
        const [showEditModal, setEditModalVisibility] = useState(false);

        const toggleEditModal = () => {
          setEditModalVisibility(!showEditModal);
        };

        const toggleDetailsVisibility = () =>
          setTopicDetailsVisibility(!showTopicDetails);

        return (
          <>
            <ViewTopic
              modal={showTopicDetails}
              toggle={toggleDetailsVisibility}
              {...original}
            />
            <EditTopic
              modal={showEditModal}
              toggle={toggleEditModal}
              original={original}
            />
            <div className="d-flex justify-content-start">
              <UncontrolledButtonDropdown direction="up">
                <StyledToggleDropDown caret={false} className="p-0">
                  <ActionButtonContainer>
                    <EllipsisH />
                  </ActionButtonContainer>
                </StyledToggleDropDown>
                <DropdownMenu>
                  <DropdownItem onClick={toggleDetailsVisibility}>
                    View
                  </DropdownItem>
                  <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      Swal.fire({
                        icon: "warning",
                        title: "Are you sure you want to delete this topic?",
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        preConfirm: (login) => {
                          return handleTopicDelete(_id);
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CreateTopic modal={showCreateModal} toggle={toggleCreateModal} />
      <DashboardLayout autoheight>
        <Container fluid className="px-0">
          <FilterBanner value={searchText} onInputChange={onInputChange} />
          <TableContainer className="mt-3 mb-5 py-3">
            <Row className="mb-4">
              <Col>
                <AddButton onClick={toggleCreateModal}>create topic</AddButton>
              </Col>
              <Col className="d-flex justify-content-end">
                <IconButton
                  active={tableLayout === "grid"}
                  onClick={() => switchTableLayout("grid")}
                >
                  <GridIcon />
                </IconButton>
                <IconButton
                  className="ml-3"
                  active={tableLayout === "list"}
                  onClick={() => switchTableLayout("list")}
                >
                  <ListIcon />
                </IconButton>
              </Col>
            </Row>
            <DataTable
              DATACOLUMNS={columns}
              TABLEDATA={topics}
              tablelayout={tableLayout}
              GridComponent={TopicCard}
              searchtext={searchText}
            />
          </TableContainer>
        </Container>
      </DashboardLayout>
    </>
  );
}

export default TopicsList;
