import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import styled from "styled-components";
import DashboardLayout from "../layouts/DashboardLayout";
import CounsellorList from "./CounsellorList";

const StyledContainer = styled(Container)`
  border-bottom: 1px solid #cccccc;
`;

const TabItem = styled(NavLink)`
  // background-color: #dfdfdf !important;
`;

function Counsellors() {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <DashboardLayout autoheight>
      <StyledContainer fluid className="pt-3 px-5">
        <Nav tabs>
          <NavItem>
            <TabItem
              className={`${activeTab === "1" && "active"}`}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              Manage Counsellors
            </TabItem>
          </NavItem>
        </Nav>
      </StyledContainer>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <CounsellorList />
        </TabPane>
      </TabContent>
    </DashboardLayout>
  );
}

export default Counsellors;
