import React, { useEffect, useState } from "react";
import DataTable, {
  ActionButtonContainer,
  StyledToggleDropDown,
  TableContainer,
} from "../components/DataTable";
import FilterBanner from "../components/FilterBanner";
import UserDropDown from "../components/UserDropDown";
import { Col, Container, Row, UncontrolledButtonDropdown } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchVolunteers } from "../redux/actions/volunteers";
import ProfileModal from "../components/modals/ProfileModal";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import { ReactComponent as Check } from "../assets/images/check.svg";
import { ReactComponent as Times } from "../assets/images/times.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Api from "../utils/api";
import RejectVolunteerModal from "../components/modals/RejectVolunteerModal";

const MySwal = withReactContent(Swal);

const SidebarContainer = styled(Container)`
  background-color: #dfdfdf;
`;

const SideBarButton = styled.button`
  background-color: ${({ active }) => (active ? "#E236F1" : "#ffffff")};
  color: ${({ active }) => (active ? "#ffffff" : "#000000")};
  height: 41px;
  font-size: 0.9rem;
  font-family: ${({ active }) =>
    active ? "SFProDisplay-Bold" : "SFProDisplay-Regular"};
  border: 0;
  border-radius: 10px;
  &:hover {
    color: #ffffff;
    background-color: #e236f1;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const IconButton = styled.button`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border: 0;
  border-radius: 100px;
`;

function PeerSupportVolunteers() {
  const dispatch = useDispatch();
  const [approvalStatus, setApprovalStatus] = useState("unapproved");
  const [activeProfile, setActiveProfile] = useState("");
  const [showProfileModal, setProfileModalVisibility] = useState(false);
  const volunteers = useSelector((state) => state.volunteers.volunteers);

  const [searchText, setSearchText] = useState("");

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  useEffect(() => {
    dispatch(fetchVolunteers(approvalStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalStatus]);

  const toggleProfileModal = () => setProfileModalVisibility(!showProfileModal);

  const handleApproval = (_id) => {
    return Api()
      .approveVolunteer(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchVolunteers(approvalStatus));
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  const columns = [
    {
      Header: "User ID",
      accessor: "_id",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { name, _id, isVolunteer } = original;
        const [showRejectionModal, setRejectionModalVisibility] =
          useState(false);

        const toggleRejectionModal = () => {
          setRejectionModalVisibility(!showRejectionModal);
        };

        return (
          <>
            <RejectVolunteerModal
              modal={showRejectionModal}
              toggle={toggleRejectionModal}
              {...original}
            />
            <div className="d-flex justify-content-start">
              {approvalStatus === 'unapproved' && (
                <>
                  <IconButton
                    className="mr-2"
                    color="#610069"
                    onClick={() =>
                      Swal.fire({
                        icon: 'question',
                        title: 'Are you sure you want to approve this user?',
                        confirmButtonText: 'Proceed',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true,
                        cancelButtonColor: '#d33',
                        preConfirm: (login) => {
                          return handleApproval(_id);
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      })
                    }
                  >
                    <Check />
                  </IconButton>
                  <IconButton
                    className="mr-2"
                    color="#E236F1"
                    onClick={toggleRejectionModal}
                  >
                    <Times />
                  </IconButton>
                </>
              )}
              <UncontrolledButtonDropdown direction="up">
                <StyledToggleDropDown caret={false} className="p-0">
                  <ActionButtonContainer>
                    <EllipsisH />
                  </ActionButtonContainer>
                </StyledToggleDropDown>
                <UserDropDown
                  name={name}
                  date={Date.now()}
                  options={[
                    {
                      name: 'View User Profile',
                      onClick: () => {
                        setActiveProfile(_id);
                        toggleProfileModal();
                      },
                    },
                    !isVolunteer
                      ? {
                          name: 'Approve User',
                          onClick: () =>
                            Swal.fire({
                              icon: 'question',
                              title:
                                'Are you sure you want to approve this user?',
                              confirmButtonText: 'Proceed',
                              cancelButtonText: 'Cancel',
                              showCancelButton: true,
                              cancelButtonColor: '#d33',
                              preConfirm: (login) => {
                                return handleApproval(_id);
                              },
                              allowOutsideClick: () => !Swal.isLoading(),
                            }),
                        }
                      : {
                          name: 'Reject User',
                          onClick: () => toggleRejectionModal(),
                        },
                  ]}
                />
              </UncontrolledButtonDropdown>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <ProfileModal
        modal={showProfileModal}
        toggle={toggleProfileModal}
        activeProfile={activeProfile}
      />
      <Container fluid className="px-0">
        <StyledRow className="no-gutter my-4 mx-5">
          <Col xs="12" sm="12" md="3" lg="3" className="p-0">
            <SidebarContainer className="h-100 pt-5">
              {[
                { name: "Unapproved Volunteers", value: "unapproved" },
                { name: "Approved Volunteers", value: "approved" },
                { name: "Rejected Volunteers", value: "rejected" },
              ].map((el) => (
                <SideBarButton
                  key={el.value}
                  active={el.value === approvalStatus}
                  className="mb-2 w-100"
                  onClick={() => setApprovalStatus(el.value)}
                >
                  {el.name}
                </SideBarButton>
              ))}
            </SidebarContainer>
          </Col>
          <Col xs="12" sm="12" md="9" lg="9" className="px-0">
            <FilterBanner value={searchText} onInputChange={onInputChange} />
            <TableContainer className="mt-3 mb-5 py-3">
              <DataTable
                DATACOLUMNS={columns}
                TABLEDATA={volunteers}
                searchtext={searchText}
              />
            </TableContainer>
          </Col>
        </StyledRow>
      </Container>
    </div>
  );
}

export default PeerSupportVolunteers;
