import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import AppBar from "../components/AppBar";
import SubMenu from "../components/SubMenu";

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  height: ${({ autoheight }) =>
    autoheight ? "auto" : "calc(100vh - (130px))"};
  @media (max-width: 768px) {
    height: auto;
  }
`;

function DashboardLayout({ children, autoheight = false }) {
  return (
    <div>
      <AppBar />
      <SubMenu />
      <StyledContainer autoheight={autoheight} fluid className="px-0">
        {children}
      </StyledContainer>
    </div>
  );
}

export default DashboardLayout;
