import React, { useEffect, useState } from "react";
import DataTable, {
  ActionButtonContainer,
  OutlinedButton,
  StyledToggleDropDown,
  TableContainer,
} from "../components/DataTable";
import FilterBanner from "../components/FilterBanner";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import UserDropDown from "../components/UserDropDown";
import { Container, UncontrolledButtonDropdown } from "reactstrap";
import { fetchUsers } from "../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";
import ProfileModal from "../components/modals/ProfileModal";
import Swal from "sweetalert2";
import Api from "../utils/api";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function UserList() {
  const [activeProfile, setActiveProfile] = useState("");
  const [showProfileModal, setProfileModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);

  const [searchText, setSearchText] = useState("");

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  const toggleProfileModal = () => setProfileModalVisibility(!showProfileModal);

  const onUserDisable = (_id) => {
    return Api()
      .disableUser(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchUsers());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  const onUserEnable = (_id) => {
    return Api()
      .enableUser(_id)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchUsers());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  const onUserDelete = (_id) => {
    return Api()
      .deleteUser(_id)
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          if (res.data.status === "success") {
            dispatch(fetchUsers());
            MySwal.fire({
              icon: "success",
              title: res.data.message,
            });
          }
          if (res.data.status === "error") {
            MySwal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        MySwal.fire({
          icon: "error",
          title: err,
        })
      );
  };

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: "User ID",
      accessor: "_id",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: " ",
      Cell: ({ row }) => {
        const { original } = row;
        const { _id } = original;
        return (
          <div className="d-flex justify-content-end">
            <OutlinedButton
              className="px-4"
              onClick={() => {
                setActiveProfile(_id);
                toggleProfileModal();
              }}
            >
              View Profile
            </OutlinedButton>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { name, _id, dateCreated = Date.now(), blocked } = original;
        // console.log(original);
        return (
          <div className="d-flex justify-content-start">
            <UncontrolledButtonDropdown direction="up">
              <StyledToggleDropDown caret={false} className="p-0">
                <ActionButtonContainer>
                  <EllipsisH />
                </ActionButtonContainer>
              </StyledToggleDropDown>
              <UserDropDown
                name={name}
                date={dateCreated}
                options={[
                  {
                    name: 'View User Profile',
                    onClick: () => {
                      setActiveProfile(_id);
                      toggleProfileModal();
                    },
                  },
                  // { name: "View Upcoming Sessions", onClick: () => {} },
                  // { name: "View Session History", onClick: () => {} },
                  // { name: "Assign Badge", onClick: () => {} },
                  !blocked
                    ? {
                        name: "Disable Account",
                        onClick: () =>
                          Swal.fire({
                            icon: "question",
                            title:
                              "Are you sure you want to disable this user?",
                            confirmButtonText: "Proceed",
                            cancelButtonText: "Cancel",
                            showCancelButton: true,
                            cancelButtonColor: "#d33",
                            preConfirm: (login) => {
                              return onUserDisable(_id);
                            },
                            allowOutsideClick: () => !Swal.isLoading(),
                          }),
                      }
                    : {
                        name: "Enable Account",
                        onClick: () =>
                          Swal.fire({
                            icon: "question",
                            title:
                              "Are you sure you want to enable this blocked user?",
                            confirmButtonText: "Proceed",
                            cancelButtonText: "Cancel",
                            showCancelButton: true,
                            cancelButtonColor: "#d33",
                            preConfirm: (login) => {
                              return onUserEnable(_id);
                            },
                            allowOutsideClick: () => !Swal.isLoading(),
                          }),
                      },
                  {
                    name: "Delete User",
                    onClick: () =>
                      Swal.fire({
                        icon: "question",
                        title: "Are you sure you want to Delete this user?",
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        preConfirm: (login) => {
                          return onUserDelete(_id);
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      }),
                  },
                ]}
              />
            </UncontrolledButtonDropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Container fluid className="px-0">
      <ProfileModal
        modal={showProfileModal}
        toggle={toggleProfileModal}
        activeProfile={activeProfile}
      />
      <FilterBanner value={searchText} onInputChange={onInputChange} />
      <TableContainer className="mt-3 mb-5 py-3">
        <DataTable
          DATACOLUMNS={columns}
          TABLEDATA={users}
          searchtext={searchText}
        />
      </TableContainer>
    </Container>
  );
}

export default UserList;
