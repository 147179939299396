import { FETCH_VOLUNTEERS } from ".";
import Api from "../../utils/api";

export const fetchVolunteers = (status) => async (dispatch) => {
  await Api()
    .getVolunteers(status)
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_VOLUNTEERS,
            payload: res.data?.data ?? [],
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
