import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import DataTable, {
  OutlinedButton,
  TableContainer,
} from "../components/DataTable";
import FilterBanner from "../components/FilterBanner";
import { ReactComponent as EllipsisH } from "../assets/images/ellipsis-h.svg";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPastSessions } from "../redux/actions/sessions";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ViewSessionNotes from "../components/modals/ViewSessionNotes";
import styled from "styled-components";
import ViewSessionDetails from "../components/modals/ViewSessionDetails";
import EditSession from "../components/modals/EditSession";
import { fetchCounsellors } from "../redux/actions/counsellors";
dayjs.extend(advancedFormat);

const ActionButton = styled.button`
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #cacaca;
  border: 0;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e236f1;
  }
`;

function PastSessionsList() {
  const [activeSession, setActiveSession] = useState("");
  const [showSessionNotesModal, setSessionNotesModalVisibility] =
    useState(false);
  const dispatch = useDispatch();
  const counsellors = useSelector((state) => state.counsellors.counsellors);
  const sessions = useSelector((state) => state.sessions.pastSessions);
  const [searchText, setSearchText] = useState("");

  const onInputChange = ({ target }) => {
    setSearchText(target.value);
  };

  const toggleSessionNotesModal = () =>
    setSessionNotesModalVisibility(!showSessionNotesModal);

  useEffect(() => {
    dispatch(fetchPastSessions());
    dispatch(fetchCounsellors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: "Session Name",
      accessor: "name",
    },
    {
      Header: "User's Name",
      accessor: "user.name",
    },
    {
      Header: "counsellor's Name",
      accessor: "counsellor.name",
    },
    {
      Header: "Session Date",
      accessor: "startTime",
      Cell: ({ value }) => {
        return dayjs(value).format("Do MMM YYYY");
      },
    },
    {
      Header: "Session Time",
      Cell: ({ row, value }) => {
        const { original } = row;
        const { startTime, endTime } = original;
        return `${dayjs(startTime).format("hh:mm A")} - ${dayjs(endTime).format(
          "hh:mm A"
        )}`;
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { _id } = original;
        const [showSessionModal, setSessionModalVisibility] = useState(false);
        const [showEditModal, setEditModalVisibility] = useState(false);

        const toggleSessionModal = () =>
          setSessionModalVisibility(!showSessionModal);

        const toggleEditModal = () => setEditModalVisibility(!showEditModal);

        return (
          <>
            <ViewSessionDetails
              modal={showSessionModal}
              toggle={toggleSessionModal}
              onEdit={toggleEditModal}
              original={original}
            />
            <EditSession
              modal={showEditModal}
              toggle={toggleEditModal}
              original={original}
              counsellors={counsellors}
            />
            <div className="d-flex justify-content-start">
              <OutlinedButton
                className="px-4 mr-3"
                onClick={() => {
                  setActiveSession(_id);
                  toggleSessionNotesModal();
                }}
              >
                View Session Notes
              </OutlinedButton>
              <ActionButton
                onClick={() => {
                  toggleSessionModal();
                }}
              >
                <EllipsisH />
              </ActionButton>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Container fluid className="px-0">
      <ViewSessionNotes
        modal={showSessionNotesModal}
        toggle={toggleSessionNotesModal}
        activeSession={activeSession}
      />
      <FilterBanner value={searchText} onInputChange={onInputChange} />
      <TableContainer className="mt-3 mb-5 py-3">
        <DataTable
          DATACOLUMNS={columns}
          TABLEDATA={sessions}
          searchtext={searchText}
        />
      </TableContainer>
    </Container>
  );
}

export default PastSessionsList;
