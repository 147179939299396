import React, { useReducer, useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import dayjs from "dayjs";
import styled from "styled-components";
import Api from "../../utils/api";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { fetchSessions } from "../../redux/actions/sessions";

const Button = styled.button`
  height: 44px;
  background-color: #e236f1;
  border: 0;
  color: #ffffff;
  border-radius: 40px;
`;

const EditSession = ({ modal, toggle, original, counsellors }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      startTime: original.startTime,
      endTime: original.endTime,
      counsellor: original?.counsellor?._id ?? "none",
      status: original?.status ?? "pending",
    }
  );

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setInputValues({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { counsellor } = inputValues;
    const apiData = {
      startTime: dayjs(inputValues.startTime).valueOf(),
      endTime: dayjs(inputValues.endTime).valueOf(),
      status: inputValues.status,
    };

    if (inputValues.counsellor !== "none") apiData["counsellor"] = counsellor;
    setLoading(true);
    await Api()
      .updateSession(original._id, apiData)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            toggle();
            NotificationManager.success(
              "Session Updated successfully",
              "Success"
            );
            dispatch(fetchSessions());
          }
        }
      })
      .catch((err = "Please check your connection settings and try again") =>
        NotificationManager.error("Session Updated successfully", "Success")
      );
    setLoading(false);
  };
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div className="d-flex justify-content-center w-100 text-center">
          <h5 className="text-center">Update Session</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Start Date</Label>
            <Input
              type="datetime-local"
              value={dayjs(inputValues.startTime).format("YYYY-MM-DDTHH:mm")}
              min={dayjs().format("YYYY-MM-DDTHH:mm")}
              name="startTime"
              placeholder="start date"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>End Date</Label>
            <Input
              type="datetime-local"
              value={dayjs(inputValues.endTime).format("YYYY-MM-DDTHH:mm")}
              min={dayjs(inputValues.startTime).format("YYYY-MM-DDTHH:mm")}
              name="endTime"
              placeholder="end date"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Assign Counsellor</Label>
            <Input
              type="select"
              value={inputValues.counsellor}
              name="counsellor"
              onChange={handleChange}
            >
              <option value="none">Select a counsellor</option>
              {counsellors.map((counsellor) => (
                <option key={counsellor._id} value={counsellor._id}>
                  {counsellor.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Approve Session</Label>
            <Input
              type="select"
              value={inputValues.status}
              name="status"
              onChange={handleChange}
            >
              <option value="none">Approve session</option>
              {[
                { label: "Pending", value: "pending" },
                { label: "Approved", value: "approved" },
                { label: "Rejected", value: "rejected" },
              ].map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Input>
          </FormGroup>
          <div className="d-flex justify-content-center">
            <Button type="submit" className="w-100" disabled={loading}>
              {!loading ? "Submit" : <Spinner />}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditSession;
