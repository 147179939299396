import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import styled from "styled-components";
import DashboardLayout from "../layouts/DashboardLayout";
import PeerSupportVolunteers from "./PeerSupportVolunteers";
import UserList from "./UserList";

const StyledContainer = styled(Container)`
  border-bottom: 1px solid #cccccc;
`;

const TabItem = styled(NavLink)`
  // background-color: #dfdfdf !important;
`;

function Users() {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <DashboardLayout autoheight>
      <StyledContainer fluid className="pt-3 px-5">
        <Nav tabs>
          <NavItem>
            <TabItem
              className={`${activeTab === "1" && "active"}`}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              Manage Users
            </TabItem>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === "2" && "active"}`}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              Approve Peer Support Volunteers
            </NavLink>
          </NavItem>
        </Nav>
      </StyledContainer>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <UserList />
        </TabPane>
        <TabPane tabId="2">
          <PeerSupportVolunteers />
        </TabPane>
      </TabContent>
    </DashboardLayout>
  );
}

export default Users;
