import { FETCH_DASHBOARD } from ".";
import Api from "../../utils/api";

export const fetchDashboard = (period) => async (dispatch) => {
  await Api()
    .getDashboard(period)
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_DASHBOARD,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
