import React, { useState } from "react";
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import styled from "styled-components";
import BannerBg from "../assets/images/filterbanner.png";
import { ReactComponent as SearchIcon } from "../assets/images/SearchIcon.svg";

const BannerContainer = styled(Container)`
  height: 111px;
  background-image: url(${BannerBg});
`;

const Button = styled.button`
  background-color: #e236f1;
  border-radius: 10px;
  border: 0;
  color: #ffffff;
  font-family: "SFProDisplay-Bold";
  font-size: 20px;
`;

const ToggleContainer = styled.div`
  height: 49px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 12px;
  overflow: hidden;
`;

const ToggleButton = styled.button`
  background-color: ${({ active }) => (active ? "#ffffff" : "transparent")};
  color: ${({ active }) => (active ? "#000000" : "#ffffff")};
  font-size: 0.8rem;
  border: 0;
  font-family: "SFProDisplay-Regular";
`;

function FilterBanner({ onInputChange, value }) {
  const [activeFilter, setActiveFilter] = useState("basic");

  const toggleActiveFilter = (filterType = "basic") => {
    setActiveFilter(filterType);
  };

  return (
    <BannerContainer fluid className="px-5">
      <Row className="d-flex h-100 align-items-center justify-content-center">
        <Col xs="12" sm="12" md="9" lg="9">
          <InputGroup style={{ height: "49px" }}>
            <InputGroupAddon
              addonType="append"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#ffffff",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
              onChange={toggleActiveFilter}
            >
              <div className="px-3">
                <SearchIcon />
              </div>
            </InputGroupAddon>
            <Input
              placeholder="Search for a user"
              className="h-100"
              style={{ borderRight: 0, borderLeft: 0 }}
              value={value}
              onChange={onInputChange}
            />
            <InputGroupAddon
              addonType="append"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#ffffff",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div className="px-3">
                <Button className="px-4 py-1">search</Button>
              </div>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col xs="12" sm="12" md="3" lg="3">
          <ToggleContainer className="w-100">
            <Row className="h-100 no-gutters">
              <Col xs="6" sm="6" lg="6">
                <ToggleButton
                  className="h-100 w-100"
                  active={activeFilter === "basic"}
                  onClick={() => toggleActiveFilter("basic")}
                >
                  Basic Filtering
                </ToggleButton>
              </Col>
              <Col xs="6" sm="6" lg="6">
                <ToggleButton
                  className="h-100 w-100"
                  active={activeFilter === "advanced"}
                  onClick={() => toggleActiveFilter("advanced")}
                >
                  Advanced Filtering
                </ToggleButton>
              </Col>
            </Row>
          </ToggleContainer>
        </Col>
      </Row>
    </BannerContainer>
  );
}

export default FilterBanner;
