import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import styled from "styled-components";
import DashboardLayout from "../layouts/DashboardLayout";
import ListeningEarList from "./ListeningEarList";
import PastListeningEarList from "./PastListeningEarList";
import UnscheduledListeningEarList from "./UnscheduledListeningEarList";

const StyledContainer = styled(Container)`
  border-bottom: 1px solid #cccccc;
`;

const TabItem = styled(NavLink)`
  // background-color: #dfdfdf !important;
`;

function ListeningEar() {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <DashboardLayout autoheight>
      <StyledContainer fluid className="pt-3 px-5">
        <Nav tabs>
          <NavItem>
            <TabItem
              className={`${activeTab === '1' && 'active'}`}
              onClick={() => {
                setActiveTab('1');
              }}
            >
              Listening Ear Sessions
            </TabItem>
          </NavItem>
          <NavItem>
            <TabItem
              className={`${activeTab === "2" && "active"}`}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              Unscheduled Sessions
            </TabItem>
          </NavItem>
          <NavItem>
            <TabItem
              className={`${activeTab === "3" && "active"}`}
              onClick={() => {
                setActiveTab("3");
              }}
            >
              Sessions History
            </TabItem>
          </NavItem>
        </Nav>
      </StyledContainer>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ListeningEarList />
        </TabPane>
        <TabPane tabId="2">
          <UnscheduledListeningEarList />
        </TabPane>
        <TabPane tabId="3">
          <PastListeningEarList />
        </TabPane>
      </TabContent>
    </DashboardLayout>
  );
}

export default ListeningEar;
