import { FETCH_TOPICS } from "../actions";

const initialState = {
  topics: [],
  isLoading: false,
};

export default function topics(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOPICS:
      return {
        ...state,
        topics: action.payload,
      };
    default:
      return state;
  }
}
