import React, { useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  // useAsyncDebounce,
} from "react-table";
import { Container, DropdownToggle, Row, Table } from "reactstrap";
import TablePagination from "./TablePagination";

const Styles = styled.div`
  padding: 0px;
  table {
    border-collapse: separate;
    border-spacing: 0px 5px;
    th {
      :first-child {
        border-top-left-radius: 10px;
      }
      :last-child {
        border-top-right-radius: 10px;
      }
    }

    td {
      background-color: #ffffff;
    }

    thead {
      background-color: #000000;
      color: #ffffff;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }
  }
`;

export const StyledToggleDropDown = styled(DropdownToggle)`
  background-color: transparent;
  border: 0;
  &:focus {
    outline: 0 solid red;
    border: 0;
  }
`;

export const ActionButtonContainer = styled.div`
  background-color: rgba(196, 196, 196, 0.89);
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled(Container)`
  background-color: #f3f3f3;
  border-radius: 10px;
`;

export const OutlinedButton = styled.button`
  height: 20.34px;
  border-radius: 200px;
  background-color: #ffffff;
  border: 1px solid #e236f1;
  color: #e236f1;
  font-family: SFProDisplay-Bold;
  font-size: 8px;
  text-align: center;
  &:hover {
    color: #ffffff;
    background-color: #e236f1;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{" "}
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: "1.1rem",
//           border: "0",
//         }}
//       />
//     </span>
//   );
// }

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function DataTable({
  DATACOLUMNS,
  TABLEDATA,
  tablelayout = "list",
  searchtext = "",
  GridComponent = () => null,
}) {
  const columns = React.useMemo(() => DATACOLUMNS, [DATACOLUMNS]);

  const data = React.useMemo(() => TABLEDATA, [TABLEDATA]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setGlobalFilter(searchtext);
  }, [searchtext, setGlobalFilter]);

  // Render the UI for your table
  return (
    <Styles>
      {tablelayout === "list" ? (
        <Table {...getTableProps()} responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Container fluid>
          <Row>
            {page.map((row, i) => {
              prepareRow(row);
              return <GridComponent row={row} />;
            })}
          </Row>
        </Container>
      )}
      <TablePagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={gotoPage}
      />
    </Styles>
  );
}

export default DataTable;
