import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import AppLogo from "./AppLogo";

const StyledButton = styled.button`
  background-color: #e236f1;
  border-radius: 50px;
  border-width: 0;
  height: 36.42px;
  font-family: SFProDisplay-Bold;
  //   font-size: 20px;
  color: #ffffff;
  &:hover {
    background-color: #e236f1;
    outline: 1px solid transparent;
  }
  &:focus {
    background-color: #e236f1 !important;
    outline: 1px solid transparent;
  }
`;

const UserName = styled.h6`
  font-family: SFProDisplay-Regular;
  color: #000000;
`;

function AppBar() {
  const { logout, user } = useAuth0();
  const username = user?.nickname || user?.name;

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="light" light expand="md" className="py-3 px-4">
        <NavbarBrand href="/" className="mr-auto">
          <AppLogo width="97.99" height="21" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Nav className="d-none d-md-flex">
          <Row className="align-items-center m-0">
            <NavItem className="mr-4">
              <UserName>Hi, {username}</UserName>
            </NavItem>
            <StyledButton
              className="px-4"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              sign out
            </StyledButton>
          </Row>
        </Nav>
        {/* <Collapse isOpen={!collapsed} navbar className="d-none">
          <Nav navbar>
            {dashboardRoute.map((el) => (
              <NavItem key={el.name}>
                <NavLink>
                  <Link to={el.path}>{el.name}</Link>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse> */}
      </Navbar>
    </div>
  );
}

export default AppBar;
