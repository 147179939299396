import {
  FETCH_PAST_SESSIONS,
  FETCH_SESSIONS,
  FETCH_UNSCHEDULED_SESSIONS,
} from ".";
import Api from "../../utils/api";

export const fetchSessions = () => async (dispatch) => {
  await Api()
    .getSessions()
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_SESSIONS,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};

export const fetchUnscheduledSessions = () => async (dispatch) => {
  await Api()
    .getSessions("?unscheduled=true")
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_UNSCHEDULED_SESSIONS,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};

export const fetchPastSessions = () => async (dispatch) => {
  await Api()
    .getSessions(`?past=${new Date()}`)
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_PAST_SESSIONS,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
