import React from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";
import Avatar from "react-avatar";
import styled from "styled-components";

const Name = styled.p`
  font-family: SFProDisplay-Regular;
  font-size: 12px;
  text-align: center;
`;

const JoinDate = styled.p`
  font-family: SFProDisplay-Regular;
  font-size: 6px;
  text-align: center;
`;

const Menu = styled(DropdownMenu)`
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, 0.05);
`;

const Button = styled.button`
  height: 20.34px;
  width: 100%;
  border-radius: 200px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #000000;
  font-family: SFProDisplay-Bold;
  font-size: 8px;
  text-align: center;
  &:hover {
    color: #ffffff;
    background-color: #e236f1;
  }
`;

function UserDropDown({ options = [], name, date }) {
  return (
    <Menu className="px-1">
      <DropdownItem header className="d-flex flex-column align-items-center">
        <Avatar name={name} size="27" round={true} />
        <Name className=" mt-1 mb-0 name">{name}</Name>
        <JoinDate>Joined May 2020</JoinDate>
      </DropdownItem>
      {options.map(({ name, onClick }) => (
        <Button className="mb-1" key={name} onClick={onClick}>
          {name}
        </Button>
      ))}
    </Menu>
  );
}

export default UserDropDown;
