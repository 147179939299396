import { FETCH_INTAKE_QUESTIONNAIRE } from ".";
import Api from "../../utils/api";

export const fetchIntakeQuestionnaire = () => async (dispatch) => {
  await Api()
    .getIntakeQuestionnaire()
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_INTAKE_QUESTIONNAIRE,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
