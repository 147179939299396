import apisauce from "apisauce";
// import { BASE_URI } from "../constants/AppConstants";

const BASE_URI = "https://positiveo-backend-prod.herokuapp.com";
// const BASE_URI = "http://localhost:5000";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const Api = (token, baseURL = BASE_URI) => {
  const api = apisauce.create({
    baseURL,
    headers,
  });

  let Authorization = `Bearer ${token}`;
  api.setHeaders({ Authorization, ...headers });
  return {
    getTopicCategories: () => api.get(`${baseURL}/api/v1/categories`),
    getTopics: () => api.get(`${baseURL}/api/v1/admin/topics`),
    addTopic: (body) => api.post(`${baseURL}/api/v1/admin/topics`, body),
    getListeningEar: (query = "") =>
      api.get(`${baseURL}/api/v1/admin/listening-ear/${query}`),
    getSessions: (query = "") =>
      api.get(`${baseURL}/api/v1/admin/sessions/${query}`),
    getIntakeQuestionnaire: () =>
      api.get(`${baseURL}/api/v1/admin//intake-questionnaire`),
    deleteSession: (id) => api.delete(`${baseURL}/api/v1/admin/sessions/${id}`),
    deleteTopic: (id) => api.delete(`${baseURL}/api/v1/admin/topics/${id}`),
    getSessionNote: (id) =>
      api.get(`${baseURL}/api/v1/admin/sessions/note/${id}`),
    getVolunteers: (status) =>
      api.get(`${baseURL}/api/v1/admin/volunteers/${status}`),
    approveVolunteer: (id) =>
      api.patch(`${baseURL}/api/v1/admin/approve-volunteer/${id}`),
    rejectVolunteer: (id, body) =>
      api.patch(`${baseURL}/api/v1/admin/reject-volunteer/${id}`, body),
    getCounsellors: () => api.get(`${baseURL}/api/v1/admin/counsellors`),
    getUsers: () => api.get(`${baseURL}/api/v1/admin/users`),
    getUser: (id) => api.get(`${baseURL}/api/v1/admin/users/${id}`),
    disableUser: (id) =>
      api.patch(`${baseURL}/api/v1/admin/users/disable/${id}`),
    enableUser: (id) => api.patch(`${baseURL}/api/v1/admin/users/enable/${id}`),
    deleteUser: (id) =>
      api.delete(`${baseURL}/api/v1/admin/users/delete/${id}`),
    getDashboard: (period = "month") =>
      api.get(`${baseURL}/api/v1/admin/dashboard/${period}`),
    updateTopic: (id, body) =>
      api.patch(`${baseURL}/api/v1/admin/topics/${id}`, body),
    updateSession: (id, body) =>
      api.patch(`${baseURL}/api/v1/admin/sessions/${id}`, body),
  };
};

export default Api;
