import {
  FETCH_LISTENING_EAR,
  FETCH_PAST_LISTENING_EAR,
  FETCH_UNSCHEDULED_LISTENING_EAR,
} from ".";
import Api from "../../utils/api";

export const fetchListeningEar = () => async (dispatch) => {
  await Api()
    .getListeningEar()
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_LISTENING_EAR,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};

export const fetchUnscheduledListeningEar = () => async (dispatch) => {
  await Api()
    .getListeningEar(`?unscheduled=true`)
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_UNSCHEDULED_LISTENING_EAR,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};

export const fetchPastListeningEar = () => async (dispatch) => {
  await Api()
    .getListeningEar(`?date=${Date.now()}`)
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_PAST_LISTENING_EAR,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
