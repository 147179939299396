import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import Avatar from "react-avatar";
import styled from "styled-components";
import dayjs from "dayjs";
import Api from "../../utils/api";

const Title = styled.h6`
  color: #000000;
  font-family: SFProDisplay-Bold;
`;

const Item = styled.p`
  color: #000000;
  font-family: SFProDisplay-Regular;
`;

function ProfileModal({ toggle, modal, activeProfile }) {
  const [isLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const loadData = async () => {
    setLoading(true);
    await Api()
      .getUser(activeProfile)
      .then((res) => {
        if (res.data) {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };
  useEffect(() => {
    if (activeProfile) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfile]);
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ borderBottom: 0 }} />
      <ModalBody>
        {!isLoading ? (
          <>
            <Row className="align-items-center justify-content-center mb-4">
              <Avatar name={profile.name} round size={70} />
            </Row>
            <div className="px-5">
              <Row className="mb-2">
                <Col>
                  <Title>Full Name</Title>
                </Col>
                <Col>
                  <Item>{profile.name}</Item>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Title>Email</Title>
                </Col>
                <Col>
                  <Item>{profile.email}</Item>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Title>Phone Number</Title>
                </Col>
                <Col>
                  <Item>
                    {profile.phone
                      ? `+${profile.phone.callingCode} ` + profile.phone.phone
                      : ""}
                  </Item>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Title>Date Joined</Title>
                </Col>
                <Col>
                  <Item>{dayjs(profile.dateCreated).format("MMMM YYYY")}</Item>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </ModalBody>
    </Modal>
  );
}

export default ProfileModal;
