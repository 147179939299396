import React from "react";
import { Link as Anchor, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import dashboardRoute from "../utils/constants/dashboard-route";

const StyledContainer = styled(Container)`
  border: 1px solid #cccccc;
  background-color: rgba(196, 196, 196, 0.2);
  height: 59px;
`;

const Link = styled(Anchor)`
  color: #000000;
  font-family: SFProDisplay-Regular;
  white-space: pre;
  &:hover {
    color: #000000;
    text-decoration-line: none;
  }
`;

const LinkContainer = styled.div`
  border-bottom: ${({ active }) => (active ? `3px solid #dade00` : 0)};
`;

function SubMenu() {
  const location = useLocation();
  return (
    <StyledContainer fluid className="px-5 p-0 d-none d-md-flex d-lg-block">
      <Row className="h-100">
        {dashboardRoute.map((el) => (
          <Col key={el.name} className="d-flex justify-content-center">
            <LinkContainer
              active={el.path === location.pathname}
              className="h-100 d-flex align-items-center"
            >
              <Link to={el.path}>{el.name}</Link>
            </LinkContainer>
          </Col>
        ))}
      </Row>
    </StyledContainer>
  );
}

export default SubMenu;
