import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Auth0Login from "./pages/Auth0Login";
import DashboardRoutes from "./routes/DashboardRoutes";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Auth0Login />
        </Route>
        <PrivateRoute path="/dashboard">
          <DashboardRoutes />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
