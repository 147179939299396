import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import dashboardRoute from "../utils/constants/dashboard-route";

function DashboardRoutes() {
  return (
    <Router>
      <Switch>
        {dashboardRoute.map(({ name, ...route }) => (
          <Route {...route} key={name} />
        ))}
      </Switch>
    </Router>
  );
}

export default DashboardRoutes;
