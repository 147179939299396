import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronLeft } from "../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../assets/images/chevron-right.svg";

const IconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #aaaaaa;
  height: 29px;
  width: 29px;
  border-radius: 10px;
`;

const ButtonItem = styled.button`
  border: 0;
  color: ${({ active }) => (active ? "#E236F1" : "#5f5f5f")};
`;

function TablePagination({ pageCount = 0, gotoPage, pageIndex }) {
  const pageArray = Array.from(Array(pageCount).keys()).map((i) => i + 1);
  return (
    <div className="d-flex align-items-center justify-content-center">
      <IconContainer>
        <ChevronLeft />
      </IconContainer>
      {pageArray.map((el) => (
        <ButtonItem
          key={el}
          active={pageIndex === el - 1}
          onClick={() => gotoPage(el - 1)}
        >
          {el}
        </ButtonItem>
      ))}
      <IconContainer>
        <ChevronRight />
      </IconContainer>
    </div>
  );
}

export default TablePagination;
