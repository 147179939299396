import { FETCH_USERS } from ".";
import Api from "../../utils/api";

export const fetchUsers = () => async (dispatch) => {
  await Api()
    .getUsers()
    .then((res) => {
      if (res.data) {
        if (res.data.status === "success") {
          dispatch({
            type: FETCH_USERS,
            payload: res.data.data,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};
